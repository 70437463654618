.invisible {
    visibility: hidden;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.3s ease-in;
}

@keyframes spring {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    80% {
        transform: scale(1.1);
        opacity: 0.9;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.spring {
    animation: spring 0.3s ease-in-out;
}

/* .slick-list { */
    /* -webkit-transition: height 250ms ease; */
    /* transition: height 0.25s ease-in-out; */
/* } */