.glow {
    animation: glow 3s infinite;
}

@keyframes glow {
    0% {
        text-shadow: none;
    }

    50% {
        text-shadow: 0 0 5px #43b581, 0 0 10px #43b581, 0 0 15px #43b581, 0 0 20px #43b581;
    }

    100% {
        text-shadow: none;
    }
}