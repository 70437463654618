.responsive-flex {
    flex-direction: row;
    align-items: top;
  }
  
  @media screen and (max-width: 350px) {
    .responsive-flex {
      flex-direction: column;
      align-items: flex-start;
    }
  }